/* Map container styles */
.map-container {
    position: relative;
    height: 100vh;
    width: 100vw;
  }
  
  .map-wrapper {
    height: calc(100vh - 56px);
    width: auto;
    overflow: hidden;
  }
  
  .mapboxgl-map {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: calc(100vh - 56px);
  }
  
  /* Click marker styles */
  .click-marker {
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
    border: 2px solid white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
  
  /* Filter UI styles */
  .filter-container {
    position: fixed;
    left: 20px;
    top: 80px; /* Below navbar */
    z-index: 1000;
    width: 300px;
    max-width: calc(100vw - 40px);
  }
  
  .filter-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    transition: all 0.2s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .filter-button.light {
    background-color: white;
    color: #1a1a1a;
  }
  
  .filter-button.dark {
    background-color: #2d3748;
    color: white;
  }
  
  .filter-panel {
    margin-top: 8px;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-height: calc(100vh - 180px);
    overflow-y: auto;
  }
  
  .filter-panel.light {
    background-color: white;
  }
  
  .filter-panel.dark {
    background-color: #2d3748;
    color: white;
  }
  
  .filter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .filter-title {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }
  
  .close-button {
    background: none;
    border: none;
    padding: 4px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.2s ease;
  }
  
  .close-button:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  
  .dark .close-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .activity-category {
    margin-bottom: 16px;
  }
  
  .category-title {
    font-size: 14px;
    font-weight: 600;
    color: #666;
    margin-bottom: 8px;
    padding-bottom: 4px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  .dark .category-title {
    color: #999;
    border-bottom-color: rgba(255, 255, 255, 0.1);
  }
  
  .activity-list {
    padding-right: 8px;
  }
  
  .activity-category .activity-item {
    padding-left: 12px;
  }
  
  @media (max-width: 768px) {
    .category-title {
      font-size: 13px;
    }
  }
  
  .activity-item {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .activity-item:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  
  .dark .activity-item:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .activity-checkbox {
    margin-right: 8px;
  }
  
  .activity-label {
    font-size: 14px;
    user-select: none;
  }
  
  .clear-filters {
    margin-top: 16px;
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    font-size: 12px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .clear-filters.light {
    background-color: #f3f4f6;
  }
  
  .clear-filters.dark {
    background-color: #4a5568;
    color: white;
  }
  
  .clear-filters:hover {
    opacity: 0.9;
  }
  
  /* Loading indicator */
  .loading-spinner {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-left-color: #007bff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  .dark .loading-spinner {
    border: 2px solid rgba(255, 255, 255, 0.1);
    border-left-color: #60a5fa;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  /* Map controls and scale styles */
  @media (max-width: 768px) {
    .mapboxgl-ctrl-scale {
      position: fixed;
      font-size: 12px;
      margin: 0 !important;
      bottom: 10px !important;
      right: 10px !important;
      max-width: 80px;
    }
  
    .filter-container {
      left: 10px;
      top: 70px;
      width: calc(100vw - 20px);
      max-width: 300px;
    }
  
    .filter-panel {
      max-height: calc(100vh - 160px);
    }
  
    .filter-button {
      padding: 6px 10px;
      font-size: 13px;
    }
  
    .activity-item {
      padding: 6px;
    }
  
    .activity-label {
      font-size: 13px;
    }
  }
  
  /* Map attribution styles */
  .mapboxgl-ctrl-attrib {
    bottom: 20px;
  }
  
  /* Dark mode specific styles */
  [data-theme="dark"] .filter-button {
    background-color: #2d3748;
    color: white;
  }
  
  [data-theme="dark"] .filter-panel {
    background-color: #2d3748;
    color: white;
  }
  
  [data-theme="dark"] .activity-item:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  [data-theme="dark"] .clear-filters {
    background-color: #4a5568;
    color: white;
  }
  
  /* Scrollbar styles */
  .filter-panel {
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
  }
  
  .filter-panel::-webkit-scrollbar {
    width: 6px;
  }
  
  .filter-panel::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .filter-panel::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
  }
  
  .dark .filter-panel::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.2);
  }
  
  /* Transition animations */
  .filter-panel {
    animation: slideIn 0.2s ease-out;
  }
  
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }