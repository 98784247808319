/* Legend.css */
.legend-container {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 1000;
  width: 250px;
  padding: 12px;
  color: #000;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  background-color: rgba(255, 255, 255, 0.9);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  max-height: 70vh;
}

.legend-container.dark-mode {
  background-color: rgba(30, 30, 30, 0.9);
  color: #fff;
}

.legend-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 4px 0;
  flex-shrink: 0;
}

.legend-title {
  font-weight: bold;
  font-size: 14px;
}

.legend-content {
  flex-grow: 1;
  overflow: hidden;
  position: relative;
}

.legend-items-list {
  overflow-y: auto;
  max-height: calc(70vh - 60px);
  padding-right: 6px;
}

.legend-item-header {
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.legend-item-header:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.legend-item-header.dark-mode:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.color-swatch {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 8px;
  flex-shrink: 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.legend-item-title {
  flex-grow: 1;
  font-size: 12px;
  line-height: 1.2;
  padding-right: 4px;
}

.expand-button {
  padding: 2px;
}

.opacity-slider {
  padding: 0 8px;
  margin-top: 4px;
}

/* Mobile styles */
@media (max-width: 768px) {
  .legend-container {
    left: 10px;
    right: 10px;
    bottom: 10px;
    width: calc(100% - 20px);
    max-width: 60%;
    padding: 8px;
  }

  .legend-container.collapsed {
    max-height: 40px;
    overflow: hidden;
    padding: 4px 8px;
  }

  .legend-items-list {
    max-height: calc(50vh - 60px);
  }

  .legend-title {
    font-size: 12px;
  }

  .legend-item-title {
    font-size: 11px;
  }

  .color-swatch {
    width: 12px;
    height: 12px;
  }

  .legend-item-header {
    padding: 2px;
  }

  .opacity-slider {
    padding: 0 4px;
  }
  
  .legend-header {
    padding: 0;
  }
}

/* Scrollbar styles */
.legend-items-list::-webkit-scrollbar {
  width: 6px;
}

.legend-items-list::-webkit-scrollbar-track {
  background: transparent;
}

.legend-items-list::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 3px;
}

.legend-container.dark-mode .legend-items-list::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}