.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.custom-modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 500px;
  max-width: 90vw;
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  z-index: 1001;
}

.custom-modal.dark-mode {
  background-color: #1e1e1e;
  color: #ffffff;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.modal-title {
  margin: 0;
  font-size: 1.5rem;
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: inherit;
}

.coordinates {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 15px;
}

.action-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 15px;
}

.button {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  flex: 1 0 calc(50% - 5px);
  justify-content: center;
}

.button-primary {
  background-color: #007bff;
  color: white;
}

.button-secondary {
  background-color: #6c757d;
  color: white;
}

.button.dark-mode {
  background-color: #2c2c2c;
  color: #ffffff;
}

.button-primary.dark-mode {
  background-color: #0056b3;
}

.button-secondary.dark-mode {
  background-color: #495057;
}

.permitted-activities-wrapper {
  margin-top: 20px;
}

.activities-list {
  list-style-type: none;
  padding: 0;
}

.activity-item {
  margin-bottom: 16px;
  padding: 10px;
  border-radius: 4px;
  background-color: #f8f9fa;
}

.activity-item.dark-mode {
  background-color: #2c2c2c;
}

.activity-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.activity-name {
  flex-grow: 1;
  font-weight: bold;
}

.activity-status {
  display: flex;
  align-items: center;
}

.status-indicator {
  margin-left: 8px;
  padding: 2px 6px;
  border-radius: 3px;
  font-size: 0.9em;
}

.status-indicator.yes {
  background-color: #28a745;
  color: white;
}

.status-indicator.no {
  background-color: #dc3545;
  color: white;
}

.status-indicator.maybe {
  background-color: #ffc107;
  color: black;
}

.status-indicator.dark-mode.yes {
  background-color: #198754;
}

.status-indicator.dark-mode.no {
  background-color: #dc3545;
}

.status-indicator.dark-mode.maybe {
  background-color: #ffc107;
  color: #000000;
}

.guidelines-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 8px;
}

.expand-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-right: 8px;
  display: flex;
  align-items: center;
  color: #007bff;
}

.expand-button.dark-mode {
  color: #5ea6ff;
}

.read-more-text {
  margin-left: 4px;
  font-size: 0.9rem;
}

.guidelines-paragraph {
  margin: 8px 0 0 0;
  font-size: 0.9rem;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.guidelines-paragraph.dark-mode {
  color: #cccccc;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error {
  color: #dc3545;
}

.error.dark-mode {
  color: #ff6b6b;
}

.no-activities {
  font-style: italic;
  color: #6c757d;
}

.no-activities.dark-mode {
  color: #9e9e9e;
}

.modal-title.trail-title {
  padding: 5px 10px;
  border-radius: 4px;
}

.trail-info {
  font-style: italic;
  margin-bottom: 10px;
}

.feature-type {
  font-size: .9rem;
}

.feature-name {
  font-size: 1.25rem;
}

.permitted-transportation {
  margin-bottom: 15px;
}

.permitted-transportation h4 {
  font-size: 1.1em;
  margin-bottom: 5px;
}

.permitted-transportation ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.permitted-transportation li {
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 3px 8px;
  margin: 2px;
  border-radius: 3px;
  font-size: 0.9em;
}

.dark-mode .permitted-transportation li {
  background-color: rgba(255, 255, 255, 0.2);
}

@media (max-width: 768px) {
  .custom-modal {
    padding: 15px;
    max-height: 80vh !important;
  }

  .legend-title {
    font-size: 1rem !important;
  }

  .modal-title {
    font-size: 1.2rem;
  }

  .button {
    font-size: 0.9rem;
  }

  .coordinates {
    font-size: 0.9rem;
  }

  .permitted-transportation li {
    font-size: 0.9em;
    padding: 2px 6px;
  }
 
  .activity-name {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .custom-modal {
    padding: 10px;
    max-height: 80vh !important;
  }

  .modal-title {
    font-size: 1.1rem;
  }

  .button {
    padding: 8px;
    font-size: 0.8rem;
  }

  .coordinates {
    font-size: 0.85rem;
  }

  h4 {
    font-size: 1rem !important;
  }

  .permitted-activities-wrapper {
    font-size: .9rem !important;
  }
  
  .activity-name {
    font-size: 0.9rem;
  }
}