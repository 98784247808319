@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Base styles */
:root {
  --navbar-height: 56px;
}

html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  color: #000000;
}

/* Light/Dark mode */
body.dark-mode {
  background-color: #121212;
  color: #ffffff;
}

/* Layout components */
.page-layout {
  padding-top: var(--navbar-height);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.page-content {
  flex: 1;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
}

/* Typography */
h1 {
  font-family: 'Georgia', serif;
  font-weight: bold;
  color: inherit;
  margin-bottom: 1.5rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Map styles */
.map-container {
  position: fixed;
  top: var(--navbar-height);
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}

.map-wrapper {
  width: 100%;
  height: 100%;
}

.mapboxgl-map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.click-marker {
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  border: 2px solid white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

/* Container styles */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.page-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 1rem;
}

/* Navigation styles */
.navigation {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: var(--navbar-height);
  background-color: white;
  z-index: 1000;
}

.navigation-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: auto;
}

.navigation-logo img {
  height: 40px;
}

.navigation-menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navigation-menu a {
  color: #333;
  text-decoration: none;
  font-weight: bold;
  font-size: 18px;
  transition: color 0.3s ease;
}

.navigation-menu a:hover {
  color: #007bff;
}

/* Badge styles */
.pulse-badge {
  white-space: nowrap;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0.8; }
  100% { opacity: 1; }
}

/* Button styles */
.btn {
  display: inline-block;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  background-color: #4169e1;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn:hover {
  background-color: #45a049;
}

/* Contact form styles */
.contact-form {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  min-height: 10vh;
  padding: 20px;
}

.contact-form input[type='text'],
.contact-form input[type='email'],
.contact-form textarea {
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.9);
  padding: 0.75em 1rem;
  margin-bottom: 1.5rem;
  font-size: 1rem;
  color: black;
}

.contact-form label {
  font-size: 0.825rem;
  margin-bottom: 0.5rem;
  display: block;
}

.contact-form button[type='submit'] {
  padding: 0.75em 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 1.5rem;
}

.contact-form button[type='submit']:hover {
  background-color: #0056b3;
}

/* Product styles */
.product-items {
  display: flex;
  justify-content: center;
}

.product-item {
  display: flex;
  flex-direction: row;
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  width: 100%;
}

.product-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.product-details {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.price-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.price-value {
  font-size: 2rem;
  font-weight: bold;
  color: #007bff;
}

.product-description {
  margin-bottom: 1rem;
}

.purchase-button,
.download-button {
  align-self: flex-start;
  margin-top: auto;
}

/* Map controls */
.mapboxgl-ctrl-scale {
  position: fixed;
  font-size: 10px;
  bottom: 5px !important;
  right: 5px;
  max-width: 100px;
}

.mapboxgl-ctrl-attrib {
  bottom: 20px;
}

/* Utility classes */
.mb-2 {
  margin-bottom: 0.5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

/* Responsive styles */
@media (max-width: 768px) {
  .page-content {
    padding: 1rem;
  }
  
  .container {
    padding: 0 0.5rem;
  }
  
  .page-container {
    padding: 0.5rem;
  }

  .mapboxgl-ctrl-scale {
    font-size: 12px;
    margin: 0 !important;
    bottom: 10px !important;
    right: 10px !important;
    max-width: 80px;
  }

  .product-item {
    flex-direction: column;
  }

  .product-image {
    border-radius: 8px 8px 0 0;
  }

  .product-details {
    padding: 1rem;
  }

  .title h1 {
    font-size: 1.5rem;
  }
}

/* Dark mode adjustments */
body.dark-mode .btn {
  background-color: #2d3748;
}

body.dark-mode .contact-form input,
body.dark-mode .contact-form textarea {
  background-color: #2d3748;
  border-color: #4a5568;
  color: white;
}

body.dark-mode .navigation {
  background-color: #1a202c;
}

body.dark-mode .navigation-menu a {
  color: #e2e8f0;
}

body.dark-mode .price-value {
  color: #63b3ed;
}